<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback2 />
        {{ $t('Operarative Summary') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate='addPayload' />
      <v-row class='px-2'>
        <v-col cols='12' md='6' class='py-0' lg='3' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='branchSelection'
            :items='branchList'
            outlined

            dense
            :label="$t('select_branch')"
            item-text='shop_name'
            :no-data-text="$t('no_data')"
            item-value='shop_id_pri'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model.trim='courseGroupSelect'
            :items='courseGroupList'
            outlined
            dense
            :label="$t('category')"
            item-text='coursegroup_name'
            item-value='coursegroup_id'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        item-key='number'
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <!-- <template v-slot:[`item.orderdetail_unit`]="{ item }">
          <span :class="item.orderdetail_unit == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.orderdetail_unit }}
          </span>
        </template> -->
        <template v-slot:[`item.order_create`]='{ item }'>
          <span :class="item.order_create == $t('total') ? 'font-weight-bold primary--text' : ''">
            <span v-if="item.order_create == $t('total')">{{ $t('total') }}</span>
            <span v-else>
              {{ item.order_create | sumdatetime }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.orderdetail_price`]='{ item }'>
          <span :class="item.order_create == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.orderdetail_price }}
          </span>
        </template>
        <template v-slot:[`item.orderdetail_discount`]='{ item }'>
          <span :class="item.order_create == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.orderdetail_discount }}
          </span>
        </template>
        <template v-slot:[`item.orderdetail_total`]='{ item }'>
          <span :class="item.order_create == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.orderdetail_total }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import branch from '@/api/branch'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import courseGroup from '@/api/courseManagement/courseGroup'
import { i18n } from '@/plugins/i18n'
import { reportcourse2, removeComma } from '../useExcel2'
import { sumdate, sumdatetime } from '@/plugins/filters'
import Comeback2 from '../Comeback2.vue'
import store from '@/store/app'

export default {
  components: {
    DateFilters,
    Comeback2,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const XLSX = require('xlsx')
    const courseGroupSelect = ref('')
    const courseGroupList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('category'), value: 'coursegroup_name', width: 200 },
      { text: i18n.t('branch'), value: 'shop_name', width: 200 },
      { text: i18n.t('code'), value: 'orderdetail_id', width: 120 },
      { text: i18n.t('course_name'), value: 'orderdetail_name', width: 400 },
      {
        text: i18n.t('amount'),
        value: 'orderdetail_amount',
        width: 120,
        align: 'end',
      },
      { text: i18n.t('unit'), value: 'orderdetail_unit', width: 120 },
      { text: i18n.t('date'), value: 'order_create', width: 170 },
      {
        text: i18n.t('sale_price'),
        value: 'orderdetail_price',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('discount'),
        value: 'orderdetail_discount',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'orderdetail_total',
        width: 210,
        align: 'end',
      },
    ])
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const header = ref('')
    const payload = ref({})
    const dataDetail = ref([])
    const exportLoading = ref(false)
    const branchList = ref([])
    const branchSelection = ref('')

    branch
      .branchListForSelection()
      .then(res => {
        branchSelection.value = res[0].shop_id_pri
        branchList.value = res
      })
      .catch(error => {
        console.log('error : ', error)
      })

    courseGroup
      .courseGroupList({
        searchtext: '',
        coursegroup_status_id: 1,
      })
      .then(res => {
        courseGroupList.value = res
        courseGroupList.value.unshift({
          coursegroup_name: i18n.t('all_categories'),
          coursegroup_id: '',
        })
      })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        coursegroup_id: courseGroupSelect.value,
        main_shop_id_pri: branchSelection.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const { data, datadetail } = await reportcourse2(payload)
      dataTableList.value = data
      dataDetail.value = datadetail
      header.value = `${i18n.t('report_course_summary_sales')} ${i18n.t('detail_at_day')} ${i18n.t('since')} ${sumdate(
        start,
      )} ${i18n.t('to')} ${sumdate(end)}`

      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${header.value}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานสรุปยอดขายคอร์ส */

      /*
        order_id: "RE1299"
        order_id_pri: "604394"
      */
      dataDetail.value.map(item => {
        delete item.order_id_pri

        return item
      })
      const Heading = [
        [header.value],
        [
          '#',
          `${i18n.t('branch')}`,
          `${i18n.t('category')}`,
          `${i18n.t('code')}`,
          `${i18n.t('course_name')}`,
          `${i18n.t('amount')}`,
          `${i18n.t('unit')}`,
          `${i18n.t('date')}`,
          `${i18n.t('sale_price')}`,
          `${i18n.t('discount')}`,
          `${i18n.t('net_amount')}`,
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, await removeComma(JSON.parse(JSON.stringify(dataTableList.value))), {
        header: [
          'number',
          'shop_name',
          'coursegroup_name',
          'orderdetail_id',
          'orderdetail_name',
          'orderdetail_amount',
          'orderdetail_unit',
          'order_create',
          'orderdetail_price',
          'orderdetail_discount',
          'orderdetail_total',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }]
      const wscols = [
        { wch: 6 },
        { wch: 20 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('operative_summary_report')}`)

      /*  รายชื่อลูกค้าตามยอดขาย */
      const Heading2 = [
        [
          `${i18n.t('list_of_customers_by_sales')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
            end,
          )}`,
        ],
        [
          '#',
          i18n.t('branch'),
          i18n.t('receipt_code'),
          i18n.t('date'),
          i18n.t('category'),
          i18n.t('code'),
          i18n.t('course_name'),
          i18n.t('customer_code'),
          i18n.t('name'),
          i18n.t('tel'),
          i18n.t('group_customers'),
          i18n.t('id_card'),
          i18n.t('gender'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('old'),
          i18n.t('address'),
          i18n.t('tag'),
          i18n.t('sale_price'),
          i18n.t('discount'),
          i18n.t('net_amount'),
          i18n.t('user_is_list'),
        ],
      ]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(dataDetail.value))), {
        header: [
          'number',
          'shop_name',
          'order_id',
          'order_create',
          'coursegroup_name',
          'orderdetail_id',
          'orderdetail_name',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'customer_group_name',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          'customer_tags',
          'orderdetail_price',
          'orderdetail_discount',
          'orderdetail_total',
          'user_fullname',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 18 } }]
      const wscols2 = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 20 },
        { wch: 10 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('list_of_customers_by_sales')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    watch([courseGroupSelect, branchSelection], () => {
      addPayload(payload.value.start, payload.value.end)
    })

    return {
      exportLoading,
      courseGroupSelect,
      columns,
      dataTableList,
      loading,
      options,
      courseGroupList,
      mdiFileExcelOutline,
      branchSelection,
      branchList,
      store,
      addPayload,
      exportExcel,
    }
  },
}
</script>
